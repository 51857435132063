<template>
    <div class="card card-default animated-fast fadeInLeft">
        <div class="card-body">
            <b-row>
                <b-col sm="1" class="align-self-center">
                    <img src="../../asstes/svg/plant-levels/plant-level-3.svg" width="60px; margin-top: -5px" class="mw-100 mr-1">
                </b-col>
                <b-col sm="4" class="align-self-center">
                    <span class="badge badge-primary mr-2">Location: {{ offer.plant.location_name}}</span>
                    <span class="badge badge-primary mr-2">Room: {{ offer.plant.room_name}}</span>
                    <span class="badge badge-primary mr-2">Rack: {{ offer.plant.rack_name}}</span>
                    <span class="badge badge-primary mr-2">Label: {{ offer.plant.label}}</span>
                </b-col>
                <b-col></b-col>
                <b-col sm="3" class="text-right align-self-center">
                    <span style="font-size: 1.3em; font-weight: bold">{{ offer.price | currency }} EUR<br/></span>
                      {{ offer.seller.name }}
                    <span v-if="isInterest" class="mt-1 d-block">
                       <br/><b-btn variant="secondary" :to="{name: 'Chat:Conversation', params: {conversationId: offer.plant.market.interest.conversation_id}}"><i class="fad fa-comments-alt mr-1"></i> {{ $t('growing.component.market_offer.open_chat') }}</b-btn>
                    </span>
                </b-col>
                <template v-if="offer.plant.market.interest && !offer.plant.market.interest.marked_as_paid_at || !offer.plant.market.interest">
                    <b-col sm="3" class="align-self-center text-right" v-if="!isInterest">
                        <i class="fad fa-university mr-2" v-show="offer.payment.bank_transfer"></i><i class="fab fa-bitcoin mr-2" v-show="offer.payment.bitcoin"></i>
                        <b-btn variant="primary" @click="buyNow()">{{ $t('growing.component.market_offer.buy_now') }}</b-btn>
                    </b-col>
                    <b-col sm="3" class="align-self-center text-right" v-if="isInterest">
                        <div class="btn-group" role="group">
                            <b-btn variant="primary" @click="payNow()">{{ $t('growing.component.market_offer.pay_now') }}</b-btn>
                            <b-btn variant="primary" @click="cancel()">{{ $t('growing.component.market_offer.cancel') }}</b-btn>
                        </div>
                    </b-col>
                </template>
                <template v-else>
                    <b-col sm="3" class="align-self-center text-right" v-if="isInterest">
                      {{ $t('growing.component.market_offer.waiting') }}
                    </b-col>
                </template>
            </b-row>
        </div>
    </div>
</template>
<style lang="scss">

</style>
<script>
    export default {
        props: ['offer', 'isInterest'],
        components: {},
        data() {
            return {}
        },
        methods: {
            buyNow() {

                var paymentMethods = this.$t('growing.component.market_offer.popup.buy_plant.bitcoin_only');
                if (this.offer.payment.bank_transfer && this.offer.payment.bitcoin) {
                    paymentMethods = this.$t('growing.component.market_offer.popup.buy_plant.bitcoin_or_bank');
                } else if (this.offer.payment.bank_transfer) {
                    paymentMethods = this.$t('growing.component.market_offer.popup.buy_plant.bank');
                }

              this.$swal.fire({
                title: this.$t('growing.component.market_offer.popup.info_confirmation.title'),
                text: this.$t('growing.component.market_offer.popup.info_confirmation.text'),
                showCancelButton: true,
                cancelButtonText: this.$t('growing.component.market_offer.popup.info_confirmation.cancel'),
                confirmButtonText: this.$t('growing.component.market_offer.popup.info_confirmation.confirm')
              }).then((response) => {
                if (response.value) {
                  this.$swal.fire({
                    title: this.$t('growing.component.market_offer.popup.buy_plant.title'),
                    html: this.$t('growing.component.market_offer.popup.buy_plant.text', {room_name: this.offer.plant.room_name, price: this.$options.filters.currency(this.offer.price)}) + '<br><hr><small><b>'+ this.$t('growing.component.market_offer.popup.buy_plant.hint') +'</b></small>',
                    showCancelButton: true,
                    reverseButtons: true,
                    cancelButtonText: this.$t('growing.component.market_offer.popup.buy_plant.cancel'),
                    confirmButtonText: this.$t('growing.component.market_offer.popup.buy_plant.confirm', {payment_method: paymentMethods})
                  }).then((response) => {
                    if (response.value) {
                      this.selectPaymentMethod().then((payment_method) => {
                        this.$auth.requestVerfificationCode().then(code => {
                          this.$api.post('growing/market/offers/' + this.offer.id + '/interest', {
                            payment_method: payment_method,
                            verification_code: code.verification_code
                          }).then(() => {
                            this.$emit('modified', this.offer);
                            this.payNow();
                          }).catch(response => {
                            this.$swal.fire('Failed', response.data.message, 'error');
                          });
                        });
                      })
                    }
                  })
                } else {
                  this.$router.push({name: 'Growing:MarketTerms'});
                }
              })
            },
            cancel() {
                this.$swal.fire({
                    title: this.$t('growing.component.market_offer.popup.cancel.title'),
                    text: this.$t('growing.component.market_offer.popup.cancel.text'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('growing.component.market_offer.popup.cancel.confirm'),
                    cancelButtonText: this.$t('growing.component.market_offer.popup.cancel.cancel')
                }).then(response => {
                    if (response.value) {
                        this.$api.delete('growing/market/offers/' + this.offer.id + '/interest').then(() => {
                            this.$emit('modified', this.offer);
                        })
                    }
                });
            },
            selectPaymentMethod() {
                return (new Promise((resolve) => {
                    if (this.offer.payment.bank_transfer && this.offer.payment.bitcoin) {
                        this.$swal.fire({
                            title: this.$t('growing.component.market_offer.popup.payment_method.title'),
                            cancelButtonText: this.$t('growing.component.market_offer.popup.payment_method.bank'),
                            confirmButtonText: this.$t('growing.component.market_offer.popup.payment_method.bitcoin'),
                            showCancelButton: true,
                            cancelButtonColor: 'primary',
                            allowOutsideClick: false,
                        }).then(response => {
                            if (response.value) {
                                resolve('bitcoin');
                            } else {
                                resolve('bank_transfer');
                            }
                        });
                    } else {
                        if (this.offer.payment.bank_transfer) {
                            resolve('bank_transfer');
                        } else {
                            resolve('bitcoin');
                        }
                    }
                }))
            },
            payNow() {
                this.$api.get('growing/market/offers/' + this.offer.id + '/payment-information').then(response => {
                    let interest = response.data;

                    (new Promise((resolve) => {

                        let confirmationPopup = null;

                        if (interest.payable.address) {
                            //bitcoin
                            confirmationPopup = this.$swal.fire({
                                html: 'Send <b>' + interest.price_btc + ' BTC</b> to: <br/><br/><b class="border p-2">' + interest.payable.address + '</b><br/><br/>' + this.$t('growing.component.market_offer.popup.payment.hint'),
                                confirmButtonText: this.$t('growing.component.market_offer.popup.payment.mark_as_paid'),
                                cancelButtonText: this.$t('growing.component.market_offer.popup.payment.cancel'),
                                showCancelButton: true,
                            });
                        } else {
                            //bank
                            confirmationPopup = this.$swal.fire({
                                html: 'Send <b>' + interest.price + ' EUR</b> to: <b><br/><br/><div class="text-left">Name: ' + interest.payable.name + '<br/>IBAN: ' + interest.payable.iban + '<br/>Town: ' + interest.payable.zip + ' ' + interest.payable.town + '</b></div><br/>' + this.$t('growing.component.market_offer.popup.payment.hint'),
                                confirmButtonText: this.$t('growing.component.market_offer.popup.payment.mark_as_paid'),
                                cancelButtonText: this.$t('growing.component.market_offer.popup.payment.cancel'),
                                showCancelButton: true,
                            })
                        }

                        confirmationPopup.then(response => {
                            if (response.value) {
                                resolve(this.$swal.fire({
                                    title: this.$t('growing.component.market_offer.popup.payment_confirmation.title'),
                                    text: this.$t('growing.component.market_offer.popup.payment_confirmation.text'),
                                    confirmButtonText: this.$t('growing.component.market_offer.popup.payment_confirmation.confirm'),
                                    cancelButtonText: this.$t('growing.component.market_offer.popup.payment_confirmation.cancel'),
                                    showCancelButton: true,
                                }));
                            } else {
                                resolve({value: false});
                            }
                        })

                    })).then(response => {
                        if (response.value) {
                            return this.$api.post('growing/market/offers/' + interest.offer_id + '/interest/mark-as-paid').then(response => {
                                this.$emit('modified', this.offer);
                                return true;
                            });
                        } else {
                            return false;
                        }
                    }).then(markAsPaid => {
                        if (markAsPaid) {
                            this.$swal.fire({
                                title: this.$t('growing.component.market_offer.popup.next_step.title'),
                                text: this.$t('growing.component.market_offer.popup.next_step.text')
                            })
                        }
                    });
                });
            }
        }
    }
</script>
