<template>
    <ContentWrapper>
        <breadcrumb/>
        <div class="container">
            <div class="card card-default animated-fast fadeInDown" :class="loading ? 'whirl' : ''">
                <div class="card-body">

                    <span v-if="sellable_plants">
                        <b-btn variant="primary" :to="{name: 'Growing:PlantBrowser'}" class="has-icon mr-2 mb-2"><i class="cai-plants-own"></i> {{ $t('growing.view.market.button.your_plants.label') }}</b-btn>
                        <b-btn :to="{name: 'SettingsMarket'}" class="mr-2 mb-2">{{ $t('growing.view.market.button.manage_payment_methods.label') }}</b-btn>
                    </span>

                    <b-btn variant="secondary" @click="load()" class="mr-2 mb-2"><i class="fad fa-sync-alt mr-2"></i>{{ $t('growing.view.market.button.refresh.label') }}</b-btn>
                    <b-btn variant="secondary" :to="{name: 'Growing:MarketTerms'}" class="mr-2 mb-2"><i class="fad fa-info mr-2"></i>{{ $t('growing.view.market.button.info.label') }}</b-btn>
                    <b-btn variant="secondary" @click="showHistory = !showHistory" class="mb-2"><i class="fad fa-history mr-2"></i>{{ $t('growing.view.market.button.history.label') }}</b-btn>

                    <!--hr/>

                    <div>
                        <div class="d-inline-block mr-4 mb-2">
                            <strong>{{ history.low.price | currency }} EUR</strong>
                            <br/>
                            <small>Last 7 days low</small>
                        </div>

                        <div class="d-inline-block  mr-4 mb-2">
                            <strong>{{ history.high.price | currency }} EUR</strong>
                            <br/>
                            <small>Last 7 days high</small>
                        </div>

                        <div v-if="history.trades.length" class="d-inline-block  mr-4 mb-2">
                            <strong>{{ history.trades[0].price | currency }} EUR</strong>
                            <br/>
                            <small>Last sold offer</small>
                        </div>

                        <div class="d-inline-block  mr-4 mb-2">
                            <strong>{{ history.amount }} Plants</strong>
                            <br/>
                            <small>sold last 7 days</small>
                        </div>
                    </div-->
                </div>
            </div>

          <template v-if="offers">
            <transfer-history class="animated-fast fadeInUp" v-if="showHistory"/>

            <div v-if="interests.length > 0">
              <h3 class="text-center">{{ $t('growing.view.market.title_interests') }}</h3>
              <div v-for="interest in interests" class="offer" :class="loading ? 'whirl' : ''">
                <offer :offer="interest" :is-interest="true" @modified="load"/>
              </div>
              <hr/>
            </div>
            <div v-else>
              <h3 class="text-center animated-fast fadeInUp">{{ $t('growing.view.market.title_offers') }}</h3>

              <div v-for="offer in offers" class="offer" :class="loading ? 'whirl' : ''" :key="offer.plant.label">
                <offer :offer="offer" @modified="load"/>
              </div>
              <div class="offer text-center pb-3" v-if="meta.current_page !== meta.last_page" :class="loadingMore ? 'whirl' : ''">
                  <hr/>
                  <b-btn @click="loadMore()" variant="primary">Load More</b-btn>
              </div>

              <div v-show="!offers && !loading" class="m-3 alert alert-info">
                {{ $t('growing.view.market.empty') }}
              </div>
            </div>
          </template>
        </div>
    </ContentWrapper>
</template>
<style lang="scss">

    .has-icon {
        padding-left: 45px;
        position: relative;

        i {
            position: absolute;
            font-size: 48px;
            left: -7px;
            top: -7px
        }
    }

</style>
<script>
    import TransferHistory from "../components/Market/HistoryBrowser";
    import Offer from "../components/Market/Offer";

    export default {
        components: {TransferHistory, Offer},
        data() {
            return {
                offers: null,
                interests: [],
                history: {
                    low: {
                        price: 0
                    },
                    high:  {
                        price: 0
                    },
                    trades: [],
                    amount: 0
                },
                sellable_plants: 0,
                loading: true,
                loadingMore: false,
                showHistory: false,
                meta: {
                    total: 0,
                    per_page: 0,
                    last_page: 0,
                    current_page: 0,
                }
            }
        },
        created() {
          this.load();
        },
        methods: {
            load() {
                  this.loading = true;
                  return this.$api.get('growing/market/offers').then(response => {
                      this.offers = response.data.data;
                      this.meta = response.data.meta;
                      this.interests = response.data.interests;
                      this.history = response.data.history;
                      this.sellable_plants = response.data.sellable_plants;
                      this.loading = false;
                  });
            },
            loadMore() {
                this.loadingMore = true;
                return this.$api.get('growing/market/offers?page=' + (parseInt(this.meta.current_page)+1)).then(response => {
                    this.offers = this.offers.concat(response.data.data);
                    this.meta = response.data.meta;
                    this.loadingMore = false;
                });
            }
        }
    }
</script>
