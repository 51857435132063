<template>
    <div class="card card-data-browser card-default">
        <div class="card-header">
            <i class="card-title-icon" :class="icon"></i> {{ title }}

          <nav class="float-right">
            <ul class="pagination mb-0">
              <li class="page-item"><span class="page-link" @click="prevPage" href=""><i
                  class="fad fa-arrow-left"></i></span></li>
              <li class="page-item" :class="{active: page === state.page}" v-for="page in state.pages"><span
                  class="page-link">{{page}}</span></li>
              <li class="page-item"><span class="page-link" @click="nextPage" href=""><i
                  class="fad fa-arrow-right"></i></span></li>
            </ul>
          </nav>
        </div>
        <div class="card-body p-0" :class="loading ? 'whirl' : ''">
            <div v-show="state.data.length" class="table-wrapper">
                <div class="table-responsive text-nowrap">
                    <table class="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th>{{ $t('growing.component.plant_browser.history.label') }}</th>
                            <th>{{ $t('growing.component.plant_browser.history.buyer') }}</th>
                            <th>{{ $t('growing.component.plant_browser.history.price') }}</th>
                            <th>{{ $t('growing.component.plant_browser.history.sold_at') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in state.data">
                            <td>{{ item.plant.label }}</td>
                            <td>{{ item.recipient }}</td>
                            <td>
                              <span v-show="!item.offer.is_btc">
                                <i class="fad fa-university mr-2"></i>
                                {{ item.offer.price | currency }} EUR
                              </span>
                              <span v-show="item.offer.is_btc">
                                <i class="fab fa-bitcoin mr-2"></i>
                                {{ item.offer.btc_price }} BTC
                                  <br><small>{{ item.offer.price | currency }} EUR</small>
                              </span>
                            </td>
                            <td>{{ item.offer.sold_at }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-show="!state.data.length && !loading">
                <div class="m-3 alert alert-info">
                    {{ $t('growing.component.plant_browser.history.empty') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableDataBrowser from '@/modules/Wallet/components/TableDataBrowser.vue';

    export default {
      extends: TableDataBrowser,
      props: ['preSelectType'],
      data() {
        return {
          endpoint: '/growing/market/offers/history',
          title: 'History',
          icon: 'fad fa-history',
          types: [],
          selectedType: undefined
        }
      },
    };
</script>
